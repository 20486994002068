$(() => {
  var re = /[\("]*[A-Za-zåäöÅÄÖ]+[- ]*[A-Za-zåäöÅÄÖ]+[\)"]*/g;
  var tags = ["P", "H1", "H2", "H3", "H4", "A", "BUTTON", "BLOCKQUOTE", "LI"];
    spanLatinText(re, tags);
});

function spanLatinText(regex, tags) {
    const className = "latin";

  var nodeIterator = document.createNodeIterator(
    document.body,
    NodeFilter.SHOW_TEXT,
    node => {
      if (tags.includes(node.parentNode.nodeName, 0) && !node.parentNode.closest(".skip-latin-class")) {
                return NodeFilter.FILTER_ACCEPT;
            }
        },
        false
    );

    setSpan(nodeIterator, className, regex);
}

function setSpan(nodeIterator, className, re) {

    var matches,
        currentNode,
        allText = "",
        nodes = [];

    while (currentNode = nodeIterator.nextNode()) {
        nodes.push({
            textNode: currentNode,
            start: allText.length
        });
        allText += currentNode.nodeValue;
    }

    if (!nodes.length) {
        return;
    }
    
    while ((matches = re.exec(allText)) !== null) {
      var matchLength = matches[0].length;

        if (!matchLength) {
            re.lastIndex++;
            continue;
        }

        for (let i = 0; i < nodes.length; i++) {
            let node = nodes[i];
            var nodeLength = node.textNode.nodeValue.length;

            // Skip nodes before the match
            if (node.start + nodeLength <= matches.index)
                continue;

            // Break after the match
            if (node.start >= matches.index + matchLength)
                break;

            // Split the start node if required
            if (node.start < matches.index) {
                nodes.splice(i + 1, 0, {
                    textNode: node.textNode.splitText(matches.index - node.start),
                    start: matches.index
                });
                continue;
            }

            // Split the end node if required
            if (node.start + nodeLength > matches.index + matchLength) {
                nodes.splice(i + 1, 0, {
                    textNode: node.textNode.splitText(matches.index + matchLength - node.start),
                    start: matches.index + matchLength
                });
            }

            // Set span around the current node
            var spanNode = document.createElement("span");
            spanNode.className = className;

            node.textNode.parentNode.replaceChild(spanNode, node.textNode);
            spanNode.appendChild(node.textNode);
        }
    }
}